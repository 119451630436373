import React, { useState, useEffect } from "react";
import newlaunchingimage from "../../Media/newlaunching.jpeg";
import "./Newlaunching.scss";
import heronew from "../../Media/4.png";
import heronewm from "../../Media/m.png";
const Newlaunching = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="newlaunchingcontainer" id="newlaunch">
      <img
        className="heronew"
        src={isMobile ? heronewm : heronew}
        alt="new launching"
      />
      <div className="title">New Launching</div>
      <div className="subtitle">Viola Residence at Summarecon Crown Gading</div>
      <div className="launchingimg">
        <img className="img" alt="newlaunchingscg" src={newlaunchingimage} />
      </div>
    </div>
  );
};

export default Newlaunching;
