import React from "react";
import "./Gading.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBars,
  faHouse,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import gading from "../../Media/Product/Gading";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import gadinglogo from "../../Media/gadinglogo.png";
import gadingimg from "../../Media/scg.png";
import gadingc from "../../Media/Product/Gading Carousel";

const Gading = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
  };
  return (
    <div className="gadingcontainer" id="rukoelit">
      <div className="gadinglogo">
        <img alt="gadinglogo" className="logo" src={gadinglogo} />
      </div>
      <div className="gadingcard">
        <div className="gading1card">
          <Slider {...settings}>
            {gading.map((lt6, index) => (
              <img
                className="card-image"
                key={index}
                src={lt6}
                alt={`Launching ${index + 1}`}
              ></img>
            ))}
          </Slider>
          <div className="content-card1">
            <div className="cluster-card-title1">
              <div className="cluster-price11">17 Juta/Bulan</div>
              <div className="cluster-subprice1">
                Subsidi DP, Cicilan 16 Juta/Bulan
              </div>
              <div className="cluster-title1">Gading Boulevard</div>
            </div>
            <hr className="card-separator1" />
            <div className="card-icon1">
              <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
              <span>: 12m² </span>
              <FontAwesomeIcon icon={faHouse} />
              <span>: 12m² </span>
              <FontAwesomeIcon icon={faBars} />
              <span>: 12 </span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 12</span>
            </div>
            <div className="card-button1">
              <button
                className="button1"
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6287888100388&text=Halo+Fitri,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(ruko)%20https://newlaunching-crowngading.com/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                &nbsp;Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="gadingabout">
        <div className="aboutcenter">
          <img alt="test" className="aboutimg" src={gadingimg} />
          <div className="aboutdes">
            Gading Boulevard Commercial adalah sebuah area komersial yang di
            kembangkan oleh Summarecon Crown Gading yang merupakan sebuah
            perusahaan pengembang properti yang berkantor pusat di Jakarta dan
            bisnis utamanya adalah pengembangan township. Area komersil ini
            terletak di Jakarta Utara. Area ini terdiri dari ruko-ruko yang
            dibangun di sepanjang jalan Summarecon Crown Gading. Ruko-ruko
            tersebut memiliki luas tanah sekitar 80 m² dan memiliki tiga lantai.
            Gading Boulevard Commercial merupakan ruko perdana di kawasan
            Summarecon Crown Gading dan dijual mulai dari harga 3,1 miliar
            rupiah.
          </div>
        </div>
      </div>
      <div className="gadingslider">
        <div className="slider">
          <Slider {...settings}>
            {gadingc.map((item, index) => (
              <img
                className="cluster-image"
                key={index}
                src={item}
                alt={`galery ${index + 1}`}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Gading;
