import {
  faArrowsUpDownLeftRight,
  faBuilding,
  faGem,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./About.scss";

const About = () => {
  return (
    <div className="aboutcontainer" id="about">
      <div className="abouticon">
        <div className="icon">
          <FontAwesomeIcon size="3x" icon={faBuilding} />
          <div className="icontext">Integrate All in One City</div>
        </div>
        <div className="icon">
          <FontAwesomeIcon size="3x" icon={faArrowsUpDownLeftRight} />
          <div className="icontext">Township Development 437 ha</div>
        </div>
        <div className="icon">
          <FontAwesomeIcon size="3x" icon={faGem} />
          <div className="icontext">Peaceful & Healty Lifestyle</div>
        </div>
      </div>
      <div className="aboutdes">
        <div className="title">SUMMARECON CROWN GADING</div>
        <div className="des">
          Developer Summarecon Crown Gading adalah Summarecon Agung Tbk,
          perusahaan pengembang properti terkemuka di Indonesia. Summarecon
          Agung Tbk didirikan pada tahun 1975 dan telah mengembangkan berbagai
          proyek properti yang sukses di seluruh Indonesia.
        </div>
      </div>
    </div>
  );
};

export default About;
