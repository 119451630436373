import regia7_1 from "./3-1-2.webp";
import regia7_2 from "./4-2-1.webp";
import regia7_3 from "./7-1.webp";
import regia7_4 from "./9-2-1.webp";
import regia7_5 from "./10-1.webp";
import regia7_6 from "./11-1-1.webp";
import regia7_7 from "./12-1-1.webp";
import regia7_8 from "./regia-7a-1.webp";
import regia7_9 from "./regia-7a-p-1.webp";
import regia7_10 from "./regia-7b-d-1.webp";
import regia7_11 from "./regia-7b-p-1.webp";

const regia7 = [
  regia7_1,
  regia7_2,
  regia7_3,
  regia7_4,
  regia7_5,
  regia7_6,
  regia7_7,
  regia7_8,
  regia7_9,
  regia7_10,
  regia7_11,
];
export default regia7;
