import surrounding1 from "./1-2-1.webp";
import surrounding2 from "./10-1-1.webp";
import surrounding3 from "./11-2.webp";
import surrounding4 from "./12.webp";
import surrounding5 from "./13.webp";
import surrounding6 from "./2-1-1.webp";
import surrounding7 from "./3-2-1.webp";
import surrounding8 from "./4-1-1.webp";
import surrounding9 from "./6.webp";
import surrounding10 from "./7-1-1.webp";
import surrounding11 from "./9.webp";

const surroundingimg = [
  surrounding1,
  surrounding2,
  surrounding3,
  surrounding4,
  surrounding5,
  surrounding6,
  surrounding7,
  surrounding8,
  surrounding9,
  surrounding10,
  surrounding11,
];

export default surroundingimg;
