import React from "react";
import "./Regia.scss";
import logoregia from "./Untitled-design-5-1.png";
import bgregia from "../../Media/1920x1080-SCG-1.png";
import regia5 from "../../Media/Product/Regia 5";
import regia6 from "../../Media/Product/Regia 6";
import regia7 from "../../Media/Product/Regia 7";
import regia8 from "../../Media/Product/Regia 8";
import Card from "../../Component/Card/Card";
import data from "./data.json";
const Regia = () => {
  const imageMap = {
    regia5,
    regia6,
    regia7,
    regia8,
  };
  return (
    <div className="regiacontainer">
      <div
        className="regiaheader"
        style={{ backgroundImage: `url(${bgregia})` }}
      >
        <img className="regialogo" alt="violalogo" src={logoregia} />
      </div>
      <div className="regiacard">
        {data.cards.map((card, index) => {
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
              styletitle={card.styletitle}
              styleprice={card.styleprice}
              iconcolor={card.iconcolor}
              stylebutton={card.stylebutton}
              cardbackground={card.stylebackground}
              texticon={card.texticon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Regia;
