import React from "react";
import Hero from "../../Section/Hero/Hero";
import About from "../../Section/About/About";
import Promo from "../../Section/Promo/Promo";
import Newlaunching from "../../Section/Newlaunching/Newlaunching";
import Viola from "../../Section/Viola/Viola";
import Regia from "../../Section/Regia/Regia";
import Jasmia from "../../Section/Jasmia/Jasmia";
import Gading from "../../Section/Gading/Gading";
import Surrounding from "../../Section/Surrounding/Surrounding";
import Footer from "../../Section/Footer/Footer";

import { FloatingWhatsApp } from "react-floating-whatsapp";

import "./Hero.scss";
import ScrollToAnchor from "./ScrollToHash";

const Home = () => {
  return (
    <div className="app">
      <ScrollToAnchor />
      <Hero />
      <About />
      <Promo />
      <Newlaunching />
      <Viola />
      <Regia />
      <Jasmia />
      <Gading />
      <Surrounding />
      <Footer />
      <a
        rel="noreferrer"
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6287888100388&text=Halo+Fitri,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(More%20Info)%20https://newlaunching-crowngading.com/&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
    </div>
  );
};

export default Home;
