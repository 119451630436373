import React from "react";
import "./Viola.scss";
import bgviola from "./1920x1080-SCG.png";
import violalogo from "./violalogo.png";
import Slider from "react-slick";
import vslider from "../../Media/Viola/ViolaSlider";
import viola5 from "../../Media/Product/Viola 5";
import viola6 from "../../Media/Product/Viola 6";
import viola7 from "../../Media/Product/Viola 7";
import viola8 from "../../Media/Product/Viola 8";
import data from "./data.json";
import Card from "../../Component/Card/Card";
const Viola = () => {
  const imageMap = {
    viola5,
    viola6,
    viola7,
    viola8,
  };
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="violacontainer">
      <div
        className="violaheader"
        style={{ backgroundImage: `url(${bgviola})` }}
      >
        <img className="violalogo" alt="violalogo" src={violalogo} />
      </div>
      <div className="violaslider">
        <div className="title">New Attic Room</div>
        <div className="slider">
          <Slider {...settings}>
            {vslider.map((lt6, index) => (
              <img
                className="card-image"
                key={index}
                src={lt6}
                alt={`Launching ${index + 1}`}
              ></img>
            ))}
          </Slider>
        </div>
      </div>
      <div className="violacard">
        {data.cards.map((card, index) => {
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
              styletitle={card.styletitle}
              styleprice={card.styleprice}
              iconcolor={card.iconcolor}
              stylebutton={card.stylebutton}
              cardbackground={card.stylebackground}
              texticon={card.texticon}
              subtitlestyle={card.subtitlestyle}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Viola;
