import regia8_1 from "./RNI-Films-IMG-946EF3D5-1C5D-4AE0-B112-F436A0B7DCB7-1.jpg";
import regia8_2 from "./4-2-1.webp";
import regia8_3 from "./6-3-1.webp";
import regia8_4 from "./8-2-1.webp";
import regia8_5 from "./9-2-1.webp";
import regia8_6 from "./11-1-1.webp";
import regia8_7 from "./12-1-1.webp";
import regia8_8 from "./a-1.webp";
import regia8_9 from "./regia-8-1.webp";
import regia8_10 from "./regia-8-p-1.webp";

const regia8 = [
  regia8_1,
  regia8_2,
  regia8_3,
  regia8_4,
  regia8_5,
  regia8_6,
  regia8_7,
  regia8_8,
  regia8_9,
  regia8_10,
];

export default regia8;
