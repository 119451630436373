import React from "react";
import "./Surrounding.scss";
import surroundingimg from "../../Media/Surrounding";
import Slider from "react-slick";
import map from "../../Media/Map.png";

const Surrounding = () => {
  const point = [
    "Near RS Eka Hospital",
    "Near Univ Esa Unggul",
    "Near Wisma BCA",
    "Near Sekolah BPK Penabur & Al Azhar",
    "10 menit ke Tol JORR",
    "10 menit ke Aeon Mall & IKEA",
  ];

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };
  return (
    <div className="surroundingcont" id="kawasanekslusif">
      <div className="surroundingtitle">SURROUNDING AREA</div>
      <div className="surroundingslider">
        <div className="slidercenter">
          <Slider {...settings}>
            {surroundingimg.map((item, index) => (
              <img
                className="cluster-image"
                key={index}
                src={item}
                alt={`galery ${index + 1}`}
              />
            ))}
          </Slider>
        </div>
      </div>
      <hr />
      <div className="mapcenter" id="lokasi">
        <img className="map" alt="scgmap" src={map} />
      </div>
      <div className="lokasipoint">
        <div className="lokasititle">Lokasi : </div>
        {point.map((item, index) => (
          <li className="point">{item}</li>
        ))}
      </div>
    </div>
  );
};

export default Surrounding;
