import React from "react";
import hero from "../../Media/Heroe.png";
import hero2 from "../../Media/Hero - Copy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./Hero.scss";

const Hero = () => {
  return (
    <div className="herocontainer">
      <div className="hero-image">
        <img src={hero} alt="hero" />
      </div>
      <div className="hero-description">
        <div className="description-top">
          <h3>
            Welcome to <br /> Summarecon Crown Gading
          </h3>
          <button
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=6287888100388&text=Halo+Fitri,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(More%20Info)%20https://newlaunching-crowngading.com/&type=phone_number&app_absent=0",
                "_blank"
              )
            }
            className="buttonhero"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;For More Info
          </button>
        </div>

        <img src={hero2} alt="logo summarecon crown gading" />
      </div>
    </div>
  );
};

export default Hero;
